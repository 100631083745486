// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer.tsx");
  import.meta.hot.lastModified = "1715872054673.6953";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
function Footer({
  categories,
  posts
}) {
  return <div className="sm:h-[300px] h-auto bg-emerald-700 flex flex-col sm:flex-row p-10">
      <div className="sm:w-1/5 w-1/2 p-5">
        <h2 className="text-white font-black text-2xl">Bedriftsfinans</h2>
        <div className="text-white font-normal text-sm pt-3">All content on Eiendomsmegler.no is copyright protected © 2024 Nettbureau AS.</div>
      </div>
      <div className="sm:w-4/5 flex flex-wrap sm:flex-nowrap">
        <div className="sm:w-1/4 w-1/2 sm:px-3 p-5">
          <h2 className="font-bold text-base text-white pb-4">Categories</h2>
          {categories.slice(0, 3).map(item => <div key={item._id} className="">
              <Link to={`/${item.slug.current}`}>
                <div className="text-white font-normal cursor-pointer hover:underline">{item.title}</div>
              </Link>
            </div>)}
          <Link to={`/`} className="text-white cursor-pointer expand-border">See more</Link>
        </div>
        <div className="sm:w-1/4 w-1/2 sm:px-3 p-5">
          <h2 className="font-bold text-base text-white pb-4">Posts</h2>
          {posts.slice(0, 3).map((post, i) => <div key={post._id} className="">
              <Link to={`/${post.slug.current}`}>
                <div className="text-white font-normal cursor-pointer hover:underline">{post.title}</div>
              </Link>
            </div>)}
          <Link to={`/`} className="text-white cursor-pointer expand-border">See more</Link>
        </div>
        <div className="sm:w-1/4 w-1/2 sm:px-3 p-5">
          <h2 className="font-bold text-base text-white pb-4">Services</h2>
          {posts.slice(0, 3).map((post, i) => <div key={post._id} className="">
              <Link to={`/${post.slug.current}`}>
                <div className="text-white font-normal cursor-pointer hover:underline">{post.title}</div>
              </Link>
            </div>)}
          <Link to={`/`} className="text-white cursor-pointer expand-border">See more</Link>
        </div>
        <div className="sm:w-1/4 w-1/2 sm:px-3 p-5">
          <h2 className="font-bold text-base text-white pb-4">Something else</h2>
          {posts.slice(0, 3).map((post, i) => <div key={post._id} className="">
              <Link to={`/${post.slug.current}`}>
                <div className="text-white font-normal cursor-pointer hover:underline">{post.title}</div>
              </Link>
            </div>)}
          <Link to={`/`} className="text-white cursor-pointer expand-border">See more</Link>
        </div>
      </div>


    </div>;
}
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;